import { parse } from 'partial-json';

export function parseRawJsonFromLlmOutput(inputText: string) {
  var startPosition = inputText.indexOf('{');

  if (startPosition === -1) {
    throw new Error("No '{' found, unable to parse JSON");
  }

  const leftTimes = inputText.split('{').length - 1;
  const rightTimes = inputText.split('}').length - 1;
  const isNotFinished = leftTimes !== rightTimes;

  if (isNotFinished) {
    return parse(inputText.slice(startPosition));
  }

  var endPosition = inputText.lastIndexOf('}');
  var jsonString = inputText.substring(startPosition, endPosition + 1);

  try {
    var parsedObject = JSON.parse(jsonString);

    return parsedObject;
  } catch (e: any) {
    throw new Error('JSON parsing error: ' + e.message);
  }
}
