import { Route, Routes } from 'react-router-dom';
import { AskPage } from './ask';
import { HomePage } from './home';
import { ConfirmPage } from './confirm';
import { OptionPage } from './option';
import { ResultPage } from './result';
import { BeforeOptionPage } from './beforeOption';
import { ResultCardPage } from './resultCard';

export function RouteEntry() {
  return (
    <Routes>
      <Route path="/*" element={<HomePage />} />
      <Route path="/ask" element={<AskPage />} />
      <Route path="/confirm" element={<ConfirmPage />} />
      <Route path="/option/:stepNum/*" element={<OptionPage />} />
      <Route path="/option" element={<BeforeOptionPage />} />
      <Route path="/result" element={<ResultPage />} />
      <Route path="/resultCard" element={<ResultCardPage />} />
    </Routes>
  );
}
