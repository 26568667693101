import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { trpc } from './utils/trpc';
import { httpBatchLink, splitLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { useState } from 'react';
import { RouteEntry } from './pages/index';

const router = createBrowserRouter([
  {
    path: '*',
    element: <RouteEntry />,
  },
]);

const url = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/trpc` : 'http://localhost:4000/trpc';

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          condition(op) {
            return !!op.context.useStream;
          },
          true: unstable_httpBatchStreamLink({
            url,
            headers: () => ({
              authorization: 'xxxxx',
            }),
          }),
          false: httpBatchLink({
            url,
            headers: () => ({
              authorization: 'xxxxx',
            }),
          }),
        }),
      ],
    })
  );

  return (
    <>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </trpc.Provider>
    </>
  );
}
