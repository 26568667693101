import { Dialog, DialogPanel } from '@headlessui/react';
import { useState } from 'react';
import { LuHome } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { CoinVector } from '../components/CoinVector';
import { MarkdownViewer } from '../components/MarkdownViewer';
import { PageWrapper } from '../components/PageWrapper';
import { TopBar } from '../components/TopBar';
import { Step, useGlobalStore } from '../utils/store';
import { trpc } from '../utils/trpc';

export function ResultQuestion({ question }: { question: string }) {
  return (
    <div className="flex flex-col items-center w-full py-2 mt-3 space-y-1 border border-black rounded-lg">
      <div>你的问题： </div>
      <div className="font-bold">{question}</div>
    </div>
  );
}

export function ResultContent({ step }: { step: Step & { end: true } }) {
  return (
    <>
      <div className="text-base text-[#626262]">根据你的选择，我的建议是：</div>
      <div className="">
        <CoinVector hideDashedLine className="mt-6" outerDiameter={172} innerDiameter={172}>
          <div className="text-2xl font-bold">{step.suggestionKeyword}</div>
        </CoinVector>
      </div>
      <div className="mt-10 text-[19px]">
        <div className="mb-2 font-bold">建议</div>
        <MarkdownViewer markdown={step.suggestion} />
        <div className="mb-2 font-bold">原因分析</div>
        <MarkdownViewer markdown={step.reason} />
        <div className="mb-2 font-bold">决策风格分析</div>
        <MarkdownViewer markdown={step.style} />
        <div className="rounded-2xl bg-[#FAECD7] px-5 py-3">
          {step.quotes.content}
          <div className="flex justify-end mt-3">——{step.quotes.author}</div>
        </div>
      </div>
    </>
  );
}

export function ResultPage() {
  const navigate = useNavigate();
  const question = useGlobalStore((state) => state.question);
  const steps = useGlobalStore((state) => state.steps);
  const currentStep = steps.at(-1) as Step & { end: true };

  const [shareOpen, setShareOpen] = useState(false);

  const getResultCardImageQuery = trpc.getResultCardImage.useQuery(
    {
      question,
      step: JSON.stringify(currentStep),
    },
    {
      enabled: !!question && !!currentStep,
    }
  );

  return (
    <PageWrapper>
      <TopBar
        leftButtonIcon={<LuHome className="w-6 h-6" />}
        rightElement={
          <button onClick={() => {}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 3H21V9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 14L21 3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M18 13V19C18 19.5304 17.7893 20.0391 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H11"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        }
        onLeftButtonClick={() => {
          navigate('/');
        }}
        bottomElement={<ResultQuestion question={question} />}
      />
      <div className="flex flex-col items-center mt-12">
        <ResultContent step={currentStep} />
        <ShareDialog
          baseUrl={getResultCardImageQuery.data ?? ''}
          open={shareOpen}
          onClose={() => {
            setShareOpen(false);
          }}
        />
        <div className="flex flex-col items-center pb-20 mt-8 space-y-3">
          <button
            onClick={async () => {
              setShareOpen(true);
            }}
            className="rounded-full text-white bg-[#A09380] font-bold w-64 h-[52px] text-xl border border-black"
          >
            保存/分享答案
          </button>
          <button
            onClick={() => {
              // navigate('/ask');
            }}
            className="rounded-full text-black w-64 h-[52px] text-xl border border-black"
          >
            继续提问
          </button>
        </div>
      </div>
    </PageWrapper>
  );
}

function ShareDialog({ baseUrl, open, onClose }: { baseUrl: string; open?: boolean; onClose?: () => void }) {
  return (
    <Dialog
      transition
      open={open}
      className="fixed inset-0 z-10 flex items-center transition w-screen backdrop-blur duration-300 justify-center h-screen bg-[#4F3A1A]/20 data-[closed]:opacity-0 data-[closed]:pointer-events-none"
      onClose={() => {
        onClose?.();
      }}
    >
      <DialogPanel
        transition
        className="w-full max-w-md p-6 space-y-3 pointer-events-none rounded-xl flex flex-col items-center justify-center data-[closed]:scale-90 transition duration-300 data-[closed]:opacity-0"
      >
        {baseUrl && <img src={baseUrl} className="h-[589px] pointer-events-auto" alt="share" />}
        <div className="flex flex-col space-y-3">
          <button
            onClick={() => {
              // navigate('/ask');
            }}
            className="rounded-full pointer-events-auto text-black bg-white w-64 h-[52px] text-xl border border-black"
          >
            分享
          </button>
          <button
            onClick={() => {
              if (baseUrl) {
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = baseUrl;
                link.download = 'coin-ai-result-card.png'; // Set the filename for download

                // Programmatically click the link to trigger download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }}
            className="rounded-full pointer-events-auto text-black bg-white w-64 h-[52px] text-xl border border-black"
          >
            保存至本地
          </button>
        </div>
      </DialogPanel>
    </Dialog>
  );
}
