import { create } from 'zustand/react';

export type Step =
  | {
      reply?: string;
      newQuestion?: string;
      options?: string[];
      end?: false;
    }
  | {
      suggestionKeyword: string;
      suggestion: string;
      reason: string;
      style: string;
      quotes: {
        content: string;
        author: string;
      };
      end: true;
    };

export interface State {
  question: string;
  sessionId: string;
  steps: Step[];
  states: {
    selectedOptionIndex?: number;
    customAnswerContent: string;
  }[];
}

export const useGlobalStore = create<State>((set) => ({
  question: '',
  sessionId: '',
  steps: [],
  states: [],
}));
