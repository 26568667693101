import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import { clsx } from 'clsx';

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  children?: React.ReactNode;
  direction?: 'left' | 'right';
}

export function FoldableSideMenu({ open, setOpen, children, direction = 'right' }: Props) {
  return (
    <>
      {createPortal(
        <Transition as="div" appear className="fixed z-20 inset-full" show={open}>
          <Dialog onClose={() => setOpen(false)}>
            <TransitionChild>
              <div className="fixed inset-0 z-20 flex transition backdrop-blur duration-300 items-end justify-center h-screen bg-black/20 data-[closed]:opacity-0 data-[closed]:pointer-events-none"></div>
            </TransitionChild>

            <TransitionChild
              as={Fragment}
              enter="ease-in-out transform duration-300"
              enterFrom={direction === 'right' ? 'translate-x-full' : '-translate-x-full'}
              enterTo={direction === 'right' ? 'translate-x-0' : '-translate-x-0'}
              leave="ease-in-out transform duration-200"
              leaveFrom={direction === 'right' ? 'translate-x-0' : '-translate-x-0'}
              leaveTo={direction === 'right' ? 'translate-x-full' : '-translate-x-full'}
            >
              <DialogPanel
                className={clsx(
                  'fixed inset-y-0 z-20 h-full overflow-y-auto text-base max-w-screen',
                  direction === 'right' ? 'right-0' : 'left-0'
                )}
              >
                <div className="h-full overflow-hidden bg-white md:shadow-xl md:ring-1 ring-black ring-opacity-5">
                  {children}
                </div>
              </DialogPanel>
            </TransitionChild>
          </Dialog>
        </Transition>,
        document.body
      )}
    </>
  );
}
