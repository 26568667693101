import { clsx } from 'clsx';

export function CoinVector({
  children,
  className,
  innerDiameter,
  outerDiameter,
  hideDashedLine,
}: {
  children?: React.ReactElement;
  outerDiameter: number;
  innerDiameter: number;
  className?: string;
  hideDashedLine?: boolean;
}) {
  return (
    <div
      style={{
        width: outerDiameter,
        height: outerDiameter,
      }}
      className={clsx('relative', className)}
    >
      {!hideDashedLine && (
        <svg
          className="absolute inset-0 m-auto"
          width="100%"
          height="100%"
          viewBox={`0 0 ${outerDiameter} ${outerDiameter}"`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={outerDiameter / 2}
            cy={outerDiameter / 2}
            r={outerDiameter / 2}
            stroke="black"
            stroke-width="1.13514"
            stroke-dasharray="5.68 5.68"
          />
        </svg>
      )}
      <div
        className="bg-[#FAECD7] rounded-full items-center flex justify-center m-auto absolute inset-0"
        style={{
          width: innerDiameter,
          height: innerDiameter,
        }}
      >
        {children}
      </div>
    </div>
  );
}
