import './polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import Clarity from '@microsoft/clarity';

if (process.env.REACT_APP_CLARITY_PROJECT_ID) {
  Clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);
}

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
