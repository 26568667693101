import MarkdownIt from 'markdown-it';
import { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

const md = new MarkdownIt('default', { breaks: true, html: false });

export function unescapeHtmlEntities(text: string) {
  return text.replace(
    /(&lt;|&gt;|&amp;|&quot;)/g,
    (c) => ({ '&lt;': '<', '&gt;': '>', '&amp;': '&', '&quot;': '"' }[c]!)
  );
}

export function markdownToHtml(v: string) {
  v = v.trim();

  v = sanitizeHtml(v, {
    allowedTags: ['br'],
  });
  v = unescapeHtmlEntities(v);
  return md.render(v);
}

export function MarkdownViewer({ markdown }: { markdown: string }) {
  const html = useMemo(() => markdownToHtml(markdown), [markdown]);

  return <div className="[&>p]:mb-8 text-xl" dangerouslySetInnerHTML={{ __html: html }} />;
}
