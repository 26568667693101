import { GrMenu } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { CoinVector } from '../components/CoinVector';
import { PageWrapper } from '../components/PageWrapper';
import { FoldableSideMenu } from '../components/foldableSideMenu';
import { useState } from 'react';

export function HomePage() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <PageWrapper>
      <button
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        <GrMenu className="w-6 h-6" />
      </button>
      <FoldableSideMenu direction="left" open={menuOpen} setOpen={setMenuOpen}>
        <div className="px-7 py-14 w-72">
          <h1 className="font-bold">历史提问</h1>
        </div>
      </FoldableSideMenu>
      <div className="flex flex-col items-center mt-7">
        <div className="justify-center text-2xl font-bold">写下此刻困扰你的问题</div>
        <div className="mt-2.5 text-base">或选一个触动你的问题硬币</div>
        <button
          onClick={() => {
            navigate('/ask');
          }}
        >
          <CoinVector className="mt-10" outerDiameter={256} innerDiameter={224}>
            <div className="text-2xl font-bold">点击提问</div>
          </CoinVector>
        </button>
      </div>
      <div className="flex flex-wrap justify-center gap-4 px-3 mt-14">
        {['去A公司还是B公司？', '要不要参加今晚聚餐？', '创业还是工作？', '做开发还是搞算法？'].map((v) => (
          <button
            className="flex flex-col items-center justify-center px-2 text-sm border border-black border-dashed rounded-full w-28 h-28"
            onClick={() => {
              navigate('/ask?question=' + encodeURIComponent(v));
            }}
          >
            <div>{v}</div>
          </button>
        ))}
        <div className="invisible h-0 w-28"></div>
        <div className="invisible h-0 w-28"></div>
        <div className="invisible h-0 w-28"></div>
        <div className="invisible h-0 w-28"></div>
      </div>
    </PageWrapper>
  );
}
