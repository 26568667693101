import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../components/PageWrapper';
import { useGlobalStore } from '../utils/store';
import { AnimatedApostrophe } from './option';
import { useInterval } from 'ahooks';

export function BeforeOptionPage() {
  const navigate = useNavigate();

  const steps = useGlobalStore((state) => state.steps);

  const [visibleAt, setVisibleAt] = useState(Date.now() + 200);
  const [tick, setTick] = useState(0);

  useInterval(() => {
    setTick((tick) => tick + 1);
  }, 100);

  useEffect(() => {
    if (visibleAt !== -1 && visibleAt + 1000 < Date.now() && steps.length > 0) {
      setVisibleAt(-1);
      setTimeout(() => {
        navigate('/option/0', { replace: true });
      }, 1000);
    }
  }, [visibleAt, navigate, steps.length, tick]);

  return (
    <PageWrapper>
      <div
        className={clsx(
          'fixed inset-0 flex items-center justify-center text-base duration-1000 transition-opacity',
          visibleAt !== -1 && visibleAt <= Date.now() ? 'opacity-100' : 'opacity-0'
        )}
      >
        停下来，感受你的内心
        <AnimatedApostrophe />
      </div>
    </PageWrapper>
  );
}
