import { DialogPanel, Dialog as HeadlessDialog } from '@headlessui/react';

export function Dialog({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}) {
  return (
    <>
      <HeadlessDialog
        transition
        open={open}
        className="fixed inset-0 z-10 flex items-center transition overflow-y-auto w-screen backdrop-blur duration-300 justify-center h-screen bg-black/20 data-[closed]:opacity-0 data-[closed]:pointer-events-none"
        onClose={onClose}
      >
        <DialogPanel
          transition
          className="w-full max-w-md p-6 bg-white rounded-xl data-[closed]:scale-90 transition duration-300 data-[closed]:opacity-0"
        >
          {children}
        </DialogPanel>
      </HeadlessDialog>
    </>
  );
}
