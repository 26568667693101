import ObjectID from 'bson-objectid';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CoinVector } from '../components/CoinVector';
import { Dialog } from '../components/Dialog';
import { PageWrapper } from '../components/PageWrapper';
import { TopBar } from '../components/TopBar';
import { useGlobalStore } from '../utils/store';
import { trpc } from '../utils/trpc';
import { askPrompt, defaultModel } from 'server/lib/consts';
import { useLocalStorageState } from 'ahooks';

export function AskPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [question, setQuestion] = useState(searchParams.get('question') ?? '');

  const askQuestionMutation = trpc.askQuestion.useMutation({});

  const [customPrompt, setCustomPrompt] = useLocalStorageState<string>('customPrompt');
  const [customModel, setCustomModel] = useLocalStorageState<string>('customModel');
  const [isOpenConfigPanel, setIsOpenConfigPanel] = useState(false);
  return (
    <PageWrapper>
      <TopBar
        transparent
        onLeftButtonClick={() => {
          navigate(-1);
        }}
      />

      <div className="absolute -right-[250px] -top-[246px]">
        <CoinVector innerDiameter={481} outerDiameter={548} />
      </div>

      <div className="fixed inset-0 flex flex-col items-center justify-center text-2xl font-bold pointer-events-none">
        你在纠结什么？
      </div>

      <Dialog
        open={isOpenConfigPanel}
        onClose={() => {
          setIsOpenConfigPanel(false);
        }}
      >
        <h1 className="mb-3 text-xl font-bold text-amber-900">后台配置</h1>
        <textarea
          value={customPrompt ?? askPrompt}
          onChange={(e) => {
            setCustomPrompt(e.target.value);
          }}
          className="px-5 py-3 w-full h-96 outline-none grow text-base bg-transparent placeholder-[#CECECE] border border-black rounded-[26px]"
          placeholder="输入提示词"
        />
        <input
          value={customModel ?? defaultModel}
          onChange={(e) => {
            setCustomModel(e.target.value);
          }}
          className="px-5 py-3 mt-2 w-full outline-none grow text-base bg-transparent placeholder-[#CECECE] border border-black rounded-[26px]"
          placeholder="输入模型名"
        />
        <div className="flex gap-2 mt-2">
          <button
            className="text-sm text-amber-700"
            onClick={() => {
              setCustomPrompt(undefined);
            }}
          >
            重置提示词
          </button>
          <button
            className="text-sm text-amber-700"
            onClick={() => {
              setCustomModel('gpt-4o');
            }}
          >
            gpt-4o
          </button>
          <button
            className="text-sm text-amber-700"
            onClick={() => {
              setCustomModel('claude-3-5-sonnet-latest');
            }}
          >
            claude-3-5-sonnet-latest
          </button>
        </div>
      </Dialog>
      <div className="fixed inset-x-0 bottom-0 max-w-xl px-4 py-3 mx-auto">
        <button
          onClick={() => {
            setIsOpenConfigPanel(true);
          }}
          className="mb-3 ml-5 text-sm text-amber-700"
        >
          后台配置
        </button>
        <div className="flex items-center w-full space-x-2">
          <textarea
            autoFocus
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
              e.target.style.height = '52px';
              e.target.style.height = e.target.scrollHeight + 'px';
            }}
            className="px-5 py-3 resize-none outline-none h-[52px] overflow-hidden bg-transparent grow text-base placeholder-[#CECECE] border border-black rounded-[26px]"
            placeholder="输入问题"
          />
          <button
            disabled={question === ''}
            className="disabled:bg-[#FAECD7] transition-colors bg-[#D4C2A7] rounded-full shrink-0 h-[52px] w-[52px] flex items-center justify-center"
            onClick={async () => {
              const sessionId = new ObjectID().toHexString();
              useGlobalStore.setState({ question: question, steps: [], sessionId });
              askQuestionMutation
                .mutateAsync({
                  question: question,
                  sessionId,
                  prompt: customPrompt,
                  model: customModel,
                })
                .then((data) => {
                  useGlobalStore.setState({
                    steps: [data],
                    states: [],
                  });
                });
              navigate('/confirm');
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.536 21.686C14.574 21.7807 14.6401 21.8615 14.7253 21.9175C14.8106 21.9736 14.9109 22.0022 15.0129 21.9996C15.1149 21.997 15.2136 21.9633 15.2959 21.9029C15.3781 21.8426 15.44 21.7585 15.473 21.662L21.973 2.662C22.0051 2.57339 22.0112 2.4775 21.9907 2.38555C21.9702 2.2936 21.9239 2.20939 21.8573 2.14277C21.7907 2.07616 21.7064 2.02989 21.6145 2.00939C21.5225 1.98888 21.4267 1.99499 21.338 2.027L2.33805 8.527C2.24155 8.56009 2.15747 8.62191 2.09712 8.70417C2.03677 8.78642 2.00302 8.88517 2.00041 8.98715C1.9978 9.08914 2.02644 9.18949 2.08251 9.27472C2.13857 9.35996 2.21937 9.426 2.31405 9.46399L10.2441 12.644C10.4947 12.7444 10.7225 12.8945 10.9136 13.0852C11.1047 13.276 11.2552 13.5035 11.356 13.754L14.536 21.686Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.8541 2.147L10.9141 13.086"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </PageWrapper>
  );
}
